import React, { useState, useEffect } from 'react';

import SceneWrapper from '../scenes/planets/mainScene'

function Planets({}) {

    const [data, setData] = React.useState({
        offset: 0,
        landpercent: 0,
        hueOffset: 0
    })


    return ( <>
        <SceneWrapper activePlanetData={data}></SceneWrapper>
    </> );
}

export default Planets;