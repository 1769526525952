import { useFrame, useThree } from '@react-three/fiber';
import { useState } from 'react';
import * as THREE from "three";
import React from "react";

let worldPos = new THREE.Vector3();
let rotationHolder = new THREE.Quaternion();



function CameraController(props) {

    const state = useThree()

    function targets(name) {
        return state.scene.getObjectByName(name)
    }


    useFrame((statez, delta) => {
        let targetName = ""

        switch (props.activeState) {
            case "/":
                targetName = "active"
                break;
            case "/links/":
                targetName = "active2"
                break;
            case "/music/":
                targetName = "center"
                break;
            case "/music/littlegreenmen/":
                targetName = "active2"
                break;
            case "/music/paracosm/":
                    targetName = "gone"
                    break;
            default:
                targetName = "active2"
                break;
        }

        let cameraTarget = targets(targetName)

        cameraTarget.getWorldPosition(worldPos)
        cameraTarget.getWorldQuaternion(rotationHolder)

        state.camera.position.lerp(worldPos, Math.min(1, (0.05 * 60 * delta || 0.05)))
        state.camera.quaternion.slerp(rotationHolder, Math.min(1, (0.05 * 60 * delta || 0.05)))

    })


    return (<></>);
}

export default CameraController;