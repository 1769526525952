import React, { useState, useEffect } from 'react';

import '../styles/main.css'

const backgroundCanvas = {
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    //position: "absolute",
    margin: "0px",
    zIndex: -4,
    position: "fixed",
    backgroundColor: 'black'
}

function Layout(props) {
    return (
        <div style={backgroundCanvas}>
            {props.children}
        </div>
    );
}

export default Layout;