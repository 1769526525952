import { useFrame, useThree } from '@react-three/fiber';
import { useState, useEffect } from 'react';
import { Canvas, useLoader } from '@react-three/fiber'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from 'react'

import React from 'react';

import { Environment, OrbitControls } from "@react-three/drei";

import * as THREE from "three";

let worldPos = new THREE.Vector3();
let rotationHolder = new THREE.Quaternion();



function Model() {
    const gltf = useLoader(GLTFLoader, '/tvscene.glb')
    console.log(gltf)
    return (<>
        <Suspense fallback={null}>
            <primitive object={gltf.scene} scale={0.4} />
        </Suspense>
    </>
    )
}

const backgroundCanvas = {
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    position: "absolute",
    margin: "0px",
    zIndex: -4,
    position: "fixed",
    backgroundColor: "black"
}


function CameraController({}) {
    const state = useThree()

    function targets(name) {
        return state.scene.getObjectByName(name)
    }

    let cameraTarget = targets("cameraobj")

    useFrame((statez, delta) => {
        
        

        cameraTarget.getWorldPosition(worldPos)
        cameraTarget.getWorldQuaternion(rotationHolder)

        state.camera.position.lerp(worldPos, Math.min(1, (0.05 * 60 * delta || 0.05)))
        state.camera.quaternion.slerp(rotationHolder, Math.min(1, (0.05 * 60 * delta || 0.05)))

    })

    return (
        <></>
    )
}

function MainScene() {

    useEffect(() => {
        window.addEventListener("message", (msg) => {
            let info = msg.data
            console.log(info.message)
        })
    }, [])

    return (
        <div>
            <Canvas  style={backgroundCanvas} camera={{ fov: 60, near: 0.1, far: 1003}}>
                <CameraController></CameraController>
                <Model></Model>
                
                <pointLight position={[-6.5, 0.1, -3]}></pointLight>
                
                <object3D name='cameraobj' position={[-6.5, 0.35, -3]} rotation={[0, -0.4, 0]}></object3D>
            </Canvas>
        </div>
    );
}

export default MainScene;
