import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { Canvas, useLoader } from '@react-three/fiber'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from 'react'

import React from 'react';


import * as THREE from "three";
import Effects from '../_globalComponents/Effects';
import Stars from '../planets/components/Stars';

let worldPos = new THREE.Vector3();
let rotationHolder = new THREE.Quaternion();



function Model() {
    const gltf = useLoader(GLTFLoader, '/Electronic Gems.glb')
    const objectModel = useRef()

    const threeState = useThree()



    useEffect(() => {
        console.log(objectModel)
        //objectModel.current.children[0].material = new THREE.MeshBasicMaterial({color: "white"})
        //objectModel.current.children[0].geometry = new THREE.WireframeGeometry(objectModel.current.children[0].geometry)
        //objectModel.current.children[0].geometry = new THREE.BoxGeometry()
        let bru = new THREE.LineSegments(new THREE.WireframeGeometry(objectModel.current.children[0].geometry))
        bru.name = "gem"
        threeState.scene.add(bru)

    }, [])

    useFrame((delta) => {
        objectModel.current.rotation.y += 0.01
        threeState.scene.getObjectByName("gem").rotation.y += 0.01
    })

    return (<>
        <Suspense fallback={null}>
            <mesh>
                <primitive ref={objectModel} object={gltf.scene} scale={0} />
            </mesh>

        </Suspense>
    </>
    )
}

const backgroundCanvas = {
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    position: "absolute",
    margin: "0px",
    zIndex: -4,
    position: "fixed",
    backgroundColor: "black"
}


function CameraController({ }) {
    let count = 0
    const state = useThree()

    function targets(name) {
        return state.scene.getObjectByName(name)
    }

    let cameraTarget = targets("cameraobj")



    useFrame((statez, delta) => {

        count += delta

        cameraTarget.getWorldPosition(worldPos)
        cameraTarget.getWorldQuaternion(rotationHolder)



        state.camera.position.lerp(worldPos, Math.min(1, (0.05 * 60 * delta || 0.05)))
        state.camera.quaternion.slerp(rotationHolder, Math.min(1, (0.05 * 60 * delta || 0.05)))

        state.camera.position.z = 4 + Math.sin(count)*0.2

    })

    return (
        <></>
    )
}

function MainScene() {

    useEffect(() => {
        window.addEventListener("message", (msg) => {
            let info = msg.data
            console.log(info.message)
        })
    }, [])



    return (
        <Canvas key={1} style={backgroundCanvas} camera={{ fov: 60, near: 0.1, far: 1000 }}>
            <CameraController></CameraController>
            <Model></Model>
            <Effects tvStatic={true} unrealBloomParams={[ 0.9, 1.8, 0.0]}></Effects>
            <Stars count={1029} dist={10} size={0.02}></Stars>

            <pointLight position={[-10.5, 2.1, 5]}></pointLight>

            <object3D key={1} name='cameraobj' position={[0, 1, 4]} rotation={[0, 0, 0]}></object3D>
        </Canvas>
    );
}

export default MainScene;
