import React, { useState, useRef, useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber'
import * as THREE from "three";


import { Stats } from "@react-three/drei"

import Effects from "../_globalComponents/Effects"


import Stars from "./components/Stars"
import SolarSystem from "./components/SolarSystem"
import CameraController from './components/cameraController';

import { ResizeObserver as polyfill } from '@juggle/resize-observer';
import { BoxGeometry } from 'three';


function Scene(props) {

    const ref = useRef()
    const { gl, camera, size } = useThree()


    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    return (
        <>
            <CameraController activeState={props.activeState}></CameraController>
            <SolarSystem activePlanetData={props.activePlanetData}></SolarSystem>

            <object3D position={[0, 190, 0]} rotation={[-3.14 / 2, 0, 0]} name="center"></object3D>
            <object3D position={[0, -5000, 0]} rotation={[-3.14 / 2, 0, 0]} name="gone"></object3D>

            <Stars count={1000} dist={500} size={1}></Stars>
        </>

    );
}

// const MemoizedScene = React.memo(Scene)
const MemoizedScene = Scene



// const CameraControls = () => {
//     // Get a reference to the Three.js Camera, and the canvas html element.
//     // We need these to setup the OrbitControls component.
//     // https://threejs.org/docs/#examples/en/controls/OrbitControls
//     const {
//         camera,
//         gl: { domElement },
//     } = useThree();
//     // Ref to the controls, so that we can update them on every frame using useFrame


//     const controls = useRef();
//     useFrame((state) => controls.current.update());
//     return <orbitControls ref={controls} args={[camera, domElement]} />;
// };



//some BS boilerplate used just to be able to access props like camera etc lmao

const backgroundCanvas = {
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    //position: "absolute",
    margin: "0px",
    zIndex: -4,
    position: "fixed"
}

// // extend({ OrbitControls });
// extend({ UnrealBloomPass });
// extend({ EffectComposer });
// extend({ RenderPass });



function SceneWrapper(props) {

    const [path, setPath] = useState("/")

    useEffect(() => {
        window.addEventListener("message", (msg) => {
            let info = msg.data
            setPath(info.message)
            console.log(info.message)
        })
    }, [])

    return (
        <Canvas mode="concurrent" resize={{ polyfill }} gl={{  type: THREE.FloatType, preserveDrawingBuffer: false, stencil: false, alpha: false, depth: false, antialias: false }} dpr={1}  style={backgroundCanvas} camera={{ fov: 45, near: 0.1, far: 1003 }}>
            
            <MemoizedScene activeState={path} activePlanetData={props.activePlanetData}></MemoizedScene>
            <Effects />
            {/* <Stats></Stats>          */}
        </Canvas>
    );
}

export default React.memo(SceneWrapper);