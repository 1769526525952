import React from 'react';
import Planet from "./Planet"
import Sun from "./Sun"


function SolarSystem(props) {

    return (<><Planet orbitRadius={85} size={1} hueOffset={Math.random()} offset={Math.random() * 1000} landpercent={0.44 + 0.2 * Math.random()} quality={7} name="active"></Planet>
        {[0, 10, 20, 30, 40, 50, 70, 80].map((i) => {
            return <Planet orbitRadius={i * 2 + 25} size={1} hueOffset={Math.random()} offset={Math.random() * 1000} landpercent={0.44 + 0.2 * Math.random()} quality={2}></Planet>
        })}
    </>);
}

export default React.memo(SolarSystem);