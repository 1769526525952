import React, { useRef, useEffect } from 'react';

import { useFrame } from '@react-three/fiber'
import * as THREE from "three";

// import { shaderMaterial } from '@react-three/drei';

const vertexShader = require("!!raw-loader!./shaders/planet-vertex-efficient.txt").default.toString()  //the only way I can import raw txt files into a string
const fragShader = require("!!raw-loader!./shaders/planet-frag-efficient.txt").default.toString()
const atmosVertShader = require("!!raw-loader!./shaders/atmos-vert.txt").default.toString()  //the only way I can import raw txt files into a string
const atmosFragShader = require("!!raw-loader!./shaders/atmos-frag.txt").default.toString()

const PlanetShaderMaterial = function (landpercent = 0.5, offset = 300, hueOffset = 0.5, quality = 5) {
    return {
        uniforms: {
            time: { value: 3.0 },
            cameraNear: { value: 1.0 },
            cameraFar: { value: 1000.0 },
            planetCenter: { value: [0, 1, 0] },
            landpercent: { value: (landpercent === null) ? 0.44 + 0.2 * Math.random() : landpercent },
            offset: { value: (offset === null) ? Math.random() * 1000 : offset },
            hueOffset: { value: (hueOffset === null) ? Math.random() : hueOffset },
            quality: { value: (quality === null) ? 5 : quality }  //set to 1 for super low quality, set 5 as highest, so choose like 5 close up, and 1 for non selected.

        }, vertexShader: vertexShader, fragmentShader: fragShader

    }
}
//, glslVersion: THREE.GLSL3        USE FOR OTHER SHIT

const AtmosphereShaderMaterial = function (coeff, power, glowColor = 0.6) {
    return {
        uniforms: {
            coeficient: {
                type: "f",
                value: coeff //0.8
            },
            power: {
                type: "f",
                value: power //1.7
            },
            glowColor: {
                type: "c",
                value: new THREE.Color("hsl(" + (glowColor * 360. - 30.0) + ", 70%, 60%)").toArray()
            },
            planetCenter: {
                value: [0, 1, 0]
            },
        }, vertexShader: atmosVertShader, fragmentShader: atmosFragShader
    }
}

let worldPos = new THREE.Vector3();

const setPlanetPosition = (planetMaterialRef, atmosphereRef, atmosphereRef2, globalRef, containerRef, orbitRadius, angle, delta) => {

    globalRef.current.rotateY((((3.1415 * 2) / (orbitRadius)) * 0.007 * 60 * delta) || (((3.1415 * 2) / (orbitRadius)) * 0.007))
    //console.log(globalRef.current.rotation.x)


    containerRef.current.getWorldPosition(worldPos)

    planetMaterialRef.current.uniforms.planetCenter.value = worldPos
    atmosphereRef.current.uniforms.planetCenter.value = worldPos
    atmosphereRef2.current.uniforms.planetCenter.value = worldPos


}


function Planet({ orbitRadius, size, name = "", album,  offset, hueOffset, landpercent, quality, active = false }) {
    const planetMeshRef = useRef()
    const planetMaterialRef = useRef()
    const containerRef = useRef()
    const globalRef = useRef()
    const atmosphereRef = useRef()
    const atmosphereRef2 = useRef()
    const cameraRotatorRef = useRef()


    let angle = Math.random() * 3.1415 * 2
    let increment = ((3.1415 * 2) / (orbitRadius)) * 0.007
    let rotateIncrement = Math.random() * 0.016 - 0.003
    let eccentricityX = Math.random() * 0.03
    let eccentricityZ = Math.random() * 0.03





    useFrame((state, delta) => {
        setPlanetPosition(planetMaterialRef, atmosphereRef, atmosphereRef2, globalRef, containerRef, orbitRadius, angle, delta)

        planetMeshRef.current.rotateY((rotateIncrement * delta * 60) || (rotateIncrement))
        cameraRotatorRef.current.rotateY(0.0005 * 60 * delta || 0.0005)

    })

    useEffect((props) => {
        planetMaterialRef.current.uniformsNeedUpdate = true //double check that we are all G my G
        atmosphereRef.current.uniformsNeedUpdate = true
        atmosphereRef2.current.uniformsNeedUpdate = true

        globalRef.current.rotateY(angle)  //initial rotation of planet from random rotation number
        globalRef.current.rotateX(eccentricityX)
        globalRef.current.rotateZ(eccentricityZ)

        cameraRotatorRef.current.rotateY(Math.random() * 3.1415 * 2)

        

        setPlanetPosition(planetMaterialRef, atmosphereRef, atmosphereRef2, globalRef, containerRef, orbitRadius, angle)
    })

    return (
        <object3D ref={globalRef}>
            <object3D ref={containerRef} position={[orbitRadius, 0, 0]}>
                <mesh ref={planetMeshRef}>
                    <icosahedronBufferGeometry attach="geometry" args={[size, 14]}>
                    </icosahedronBufferGeometry>
                    <shaderMaterial ref={planetMaterialRef} attach='material' args={[PlanetShaderMaterial(landpercent, offset, hueOffset, quality)]} uniformsNeedUpdate={true} ></shaderMaterial>
                </mesh>
                <mesh>
                    <icosahedronBufferGeometry attach="geometry" args={[size * 1.08, 7]}>
                    </icosahedronBufferGeometry>
                    <shaderMaterial ref={atmosphereRef} attach='material' uniformsNeedUpdate={true}
                        args={[AtmosphereShaderMaterial(0.8, 1.5, hueOffset)]}
                        transparent={true}
                        alphaTest={0.05}
                        depthWrite={false}
                    ></shaderMaterial>

                </mesh>
                <mesh>
                    <icosahedronBufferGeometry attach="geometry" args={[size * 1.2, 7]}>
                    </icosahedronBufferGeometry>
                    <shaderMaterial ref={atmosphereRef2} attach='material' uniformsNeedUpdate={true}
                        args={[AtmosphereShaderMaterial(0.2, 3.4, hueOffset)]}
                        transparent={true}
                        alphaTest={0.05}
                        depthWrite={false}
                        side={THREE.BackSide}
                    ></shaderMaterial>
                </mesh>
                <object3D ref={cameraRotatorRef}>
                    <object3D position={[-0.54, 0.68, 3.5]} rotation={[0.0, 0, -0.4118977]} name={name}></object3D>
                    <object3D position={[-0.72, 0.82, 4.48]} rotation={[0.0, 0, -0.3211406]} name={name + "2"}></object3D>
                </object3D>

            </object3D>
        </object3D>

    );
}



export default React.memo(Planet);